@tailwind base;

@layer base {
    html {
        font-size: 62.5%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        scroll-behavior: smooth;
    }

    body {
        @apply text-base-fluid;
        line-height: theme("lineHeight.normal");
        color: theme("colors.neutral.900");
        font-weight: 400;
    }
}

