/*
 * Pomoted Posts carousel
 */

.pomoted-posts-carousel {
    /* ... */

    .flickity-page-dots .dot {
        @apply bg-pastel-dark-blue w-0.8 h-0.8;

        &.is-selected {
            @apply bg-accent-dark-blue;
        }



        @screen md {
            display: none;
        }
    }
}

    .pomoted-posts-carousel__nav--prev,
    .pomoted-posts-carousel__nav--next {
        &.is-disabled {
            @apply cursor-not-allowed pointer-events-none opacity-20
        }
    }
