/*
 * Rich text
 * rich-text
 * rich-text--md
 * rich-text--lg
 * rich-text--light (white text)
 */

.rich-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: theme('fontFamily.headings');
        line-height: theme('lineHeight.normal');
        letter-spacing: theme('letterSpacing.wide');

        a:hover {
            @apply underline;
        }
    }

    h1 {
        @apply text-charlie-fluid;
        font-weight: theme('fontWeight.semibold');
        margin-bottom: theme('spacing.[1.6]');
    }

    h2 {
        @apply text-delta-fluid;
        font-weight: theme('fontWeight.semibold');
        margin-bottom: theme('spacing.[0.8]');
    }

    h3 {
        @apply text-echo-fluid;
        font-weight: theme('fontWeight.semibold');
        margin-bottom: theme('spacing.[0.8]');
    }

    h4 {
        @apply text-foxtrot-fluid;
        font-weight: theme('fontWeight.semibold');
        margin-bottom: theme('spacing.[0.4]');
    }

    h5 {
        @apply text-foxtrot-fluid;
        font-weight: theme('fontWeight.semibold');
        margin-bottom: theme('spacing.[0.4]');
    }

    h6 {
        @apply text-foxtrot-fluid;
        font-weight: theme('fontWeight.semibold');
        margin-bottom: theme('spacing.[0.4]');
    }

    p {
        @apply text-base;
        line-height: theme('lineHeight.normal');
        letter-spacing: theme('letterSpacing.tightest');
        margin-bottom: theme('spacing[3.2]');

        /* &:last-of-type {
            @apply mb-0;
        } */

        blockquote {
            p {
                @apply text-echo-fluid font-semibold;
            }
        }
    }

    &.rich-text--md {
        p {
            @apply text-md;
            line-height: theme('lineHeight.normal');
            letter-spacing: theme('letterSpacing.tightest');
            margin-bottom: theme('spacing[3.2]');

            /* &:last-of-type {
                @apply mb-0;
            } */
        }

        ul,
        ol {
            li {
                p {
                    @apply mb-0;
                }
            }
        }

        blockquote {
            p {
                @apply text-echo-fluid font-semibold;
            }
        }
    }

    &.rich-text--lg {
        p {
            @apply text-lg;
            line-height: theme('lineHeight.normal');
            letter-spacing: theme('letterSpacing.tightest');
            margin-bottom: theme('spacing[3.2]');

            /* &:last-of-type {
                @apply mb-0;
            } */
        }

        ul,
        ol {
            li {
                p {
                    @apply mb-0;
                }
            }
        }

        blockquote {
            p {
                @apply text-echo-fluid font-semibold;
            }
        }
    }

    &.rich-text--light {
        p {
            @apply text-white;

            /* &:last-of-type {
                @apply mb-0;
            } */
        }
    }

    a {
        @apply text-accent-light-blue underline;

        &:hover {
            @apply no-underline;
        }
    }

    ul,
    ol {
        @apply list-none list-outside pl-1.5 mb-3;

        @screen md {
            @apply pl-3;
        }

        li {
            @apply relative list-item mb-0.5;

            &::marker {
                margin-right: 15px;
            }

            p {
                @apply block mb-0;
            }

            a:hover {
                @apply underline;
            }
        }
    }

    ul {
        @apply list-disc;

        li {
            /* ... */
        }
    }

    ol {
        @apply list-decimal;

        li {
            /* ... */
        }
    }

    blockquote {
        @apply relative pt-7.8 pb-1 pl-2.4 border-none;
        margin-bottom: theme('spacing.3');

        p {
            @apply text-echo-fluid font-semibold;
        }

        &::before {
            content: '';
            @apply absolute top-0 left-0 h-full w-0.4 bg-blue-200 rounded-lg overflow-hidden;
        }

        &::after {
            content: '';
            @apply absolute top-2.2 left-2.2 w-4.2 h-4.2 text-accent-dark-blue pt-1.6 pl-2.4;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='44' height='28' viewBox='0 0 44 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.12032 27.3333C10.4803 27.3333 11.7337 26.56 12.3203 25.36L16.107 17.7866C16.4803 17.04 16.667 16.24 16.667 15.4133V3.33329C16.667 1.86663 15.467 0.666626 14.0003 0.666626H3.33366C1.86699 0.666626 0.666992 1.86663 0.666992 3.33329V14C0.666992 15.4666 1.86699 16.6666 3.33366 16.6666H8.66699L5.92032 22.16C4.72032 24.5333 6.45366 27.3333 9.12032 27.3333ZM35.787 27.3333C37.147 27.3333 38.4003 26.56 38.987 25.36L42.7737 17.7866C43.147 17.04 43.3337 16.24 43.3337 15.4133V3.33329C43.3337 1.86663 42.1337 0.666626 40.667 0.666626H30.0003C28.5337 0.666626 27.3337 1.86663 27.3337 3.33329V14C27.3337 15.4666 28.5337 16.6666 30.0003 16.6666H35.3337L32.587 22.16C31.387 24.5333 33.1203 27.3333 35.787 27.3333Z' fill='%23002072' /%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
        }

        a:hover {
            @apply underline;
        }
    }

    strong {
        @apply font-bold;
    }

    em {
        @apply italic;
    }
}
