/*
 * FAQ Accordion
 */

.faq-accordion {
    /* ... */
}

   .faq-accordion__item {

        &::after {
            content: '';
            @apply absolute top-0 left-0 w-full h-0.4 opacity-0 bg-accent-light-blue transition-all;
        }

       &.is-active {
           @apply shadow-lg border-opacity-0;

        &::after {
            @apply opacity-100;
        }

           .faq-accordion__btn {
               svg {
                   transform: rotate(180deg);
               }
           }

           .faq-accordion__content {
               @apply relative opacity-100;
           }
       }

       .faq-accordion__btn {
            svg {
                transform-origin: center center;
                will-change: transform;

                .is-active & {
                    transform: rotate(180deg);
                }
            }
        }
   }
