.mobile-menu {
    -webkit-overflow-scrolling: touch;

    .menu-is-active & {
        @apply translate-y-0;
        
    }
}

.menu-toggle {
    position: relative;
    display: block;
    width:80px;
    height: 80px;
    background-color:  #0099d8;
    transition: all 0.3s ease-in-out;

    .menu-is-active & {
        .menu-toggle__line {
            &.x {
                top: 50%;
                transform: translate(-50%,-50%) rotate(45deg);
            }

            &.y {
                opacity: 0;
            }

            &.z {
                top: 50%;
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }
    }
}

  .menu-toggle__line {
    position: absolute;
    left: 50%;
    transform-origin: center center;
    width: 1.6rem;
    height: 0.1rem;
    border-radius: 0.1rem;
    background: #1F1A47;
    transform: translate(-50%,-50%) rotate(0);
    transition: all 0.3s ease-in-out;

    &.x {
        top: calc(50% - 5px);
    }

    &.y {
        top: 50%;
    }

    &.z {
        top: calc(50% + 5px);
    }
  } 


    .mobile-nav {
        transition-delay: 300ms;

        .menu-is-active & {
            @apply opacity-100;
        }

        > * a {
            @apply no-underline;

            &:hover {
                @apply no-underline;
            }
        }

        .carpark-location {
            > * a {
                @apply text-neutral-900;
            } 
        }
    }

        .mobile-nav__dropdown-toggle {
            &::before {
                content: '';
                @apply absolute top-0 left-6/12 -translate-x-6/12 w-screen h-full bg-neutral-100 opacity-0 transition-all;

                @screen md {
                    @apply hidden;
                }
            }

            &.is-active {

                &::before {
                    @apply opacity-100;
                }

                svg {
                    @apply rotate-180;
                }
            }
        }

        .mobile-nav__location-toggle {
            &.is-active {
                svg {
                    @apply rotate-180;
                }
            }
        }
