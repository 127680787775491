/*
 * Prebooking
 */

.prebooking {
    /* ... */
}

.prebooking__tab-btn {
    &.is-active {
        @apply bg-accent-light-blue text-white;
    }
}

.prebooking__tab-panel {
    @apply hidden;
    height: 34.8rem;

    &.is-active {
        @apply flex justify-between;
    }
}
.prebooking__tab-panel-carpark {
    @apply hidden;

    &.is-active {
        @apply flex justify-between;
    }
}

.prebooking__location-item {
    &.is-active {
        @apply -translate-x-full opacity-0;
    }
}

.prebooking__car-park-item {
    &.is-active {
        @apply h-auto overflow-visible translate-x-0 opacity-100;
    }
}

.prebooking__input {
    @apply text-accent-dark-blue-2;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.4375 8H14.5625' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M8.4375 14.125L14.5625 8L8.4375 1.875' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
    background-size: 1.6rem 1.4rem;
    background-position: center right;
    background-repeat: no-repeat;

    @screen lg {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.29289 0.293378C0.68342 -0.0971417 1.31658 -0.0971416 1.70711 0.293378L7 5.58629L12.2929 0.293379C12.6834 -0.0971406 13.3166 -0.0971405 13.7071 0.293379C14.0976 0.68391 14.0976 1.31709 13.7071 1.70759L7.7071 7.70759C7.3166 8.09809 6.6834 8.09809 6.2929 7.70759L0.29289 1.70759C-0.0976301 1.31709 -0.0976301 0.683908 0.29289 0.293378Z' fill='currentColor' /%3E%3C/svg%3E%0A");
        background-size: 1.4rem 0.8rem;
        background-position: center right;
        background-repeat: no-repeat;
    }
}

.prebooking__datepicker-input {
    background: url(../) no-repeat center right;
}

.prebooking__input {
    &:focus {
        outline: none !important;
        border: none !important;
    }
}

.prebooking--desktop {
    .prebooking__locations-dropdown {
        &.is-active {
            @apply opacity-100 pointer-events-auto -translate-y-0;
        }
    }
}

.prebooking__locations-overlay {
    &.is-active {
        @apply opacity-100 pointer-events-auto;
    }

    .prebooking__overlay-header {
        &.is-active {
            @apply opacity-0 pointer-events-none;
        }
    }
}

.prebooking--car-park {
    @apply relative block;

    @screen lg {
        @apply sticky top-10.5;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS */
        @screen lg {
            @apply relative top-unset;
        }
    }
}

/* FlatPickr */

.flatpickr-calendar {
    overflow: hidden;
    box-shadow: none !important;
}

.flatpickr-months .flatpickr-month {
    height: 40px !important;
}

.flatpickr-current-month {
    padding-top: 10px !important;
}

.flatpickr-next-month {
    padding-top: 13px !important;
}

.flatpickr-calendar.hasTime .flatpickr-time {
    border-bottom: 1px solid #e6e6e6;
}

.flatpickr-confirm {
    width: 80%;
    height: 40px;
    max-height: 0px;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #002072;
    color: white;
    font-weight: bold;
    margin: 10px auto;
    border-radius: 20px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        background: #1f1a47;
    }
}

.flatpickr-confirm svg {
    display: none !important;
}

.flatpickr-confirm svg path {
    fill: inherit;
}

.flatpickr-confirm.darkTheme {
    color: white;
    fill: white;
}

.flatpickr-confirm.visible {
    max-height: 40px;
    visibility: visible;
}
