/*
 * Expandable Accordion
 */

.expandable-accordion {
    /* ... */
}


    .expandable-accordion__item {
        &.is-active {

            .expandable-accordion__indicator {
                @apply bg-white;
            }

            .expandable-accordion__indicator-svg {
                #bg {
                    @apply opacity-20;
                }

                #fg {
                    @apply opacity-100;
                }
            }

            .expandable-accordion__btn {
                svg {
                    transform-origin: center center;
                    transform: rotate(180deg);
                }
            }

            .expandable-accordion__content {
                max-height: 100rem;
                @apply opacity-100;
            }
        }
    }

        .expandable-accordion__indicator-svg {
            #bg {
                @apply opacity-0;
            }

            #fg {
                @apply opacity-0;
            }
        }


    .expandable-accordion__img-container {
        @apply w-full;

        @screen md {
            width: calc(100% + (4rem - 0.8rem));
        }

        @screen lg {
            width: calc(100% + (8rem - 1.6rem));
        }

    }
