/*
 * Locations carousel
 */

.locations-carousel {
    width: calc( ( 100% + (100vw - 100%) / 2) ); /* 100vw - 100% (the width of the container) */
}

    .locations-carousel__nav--prev,
    .locations-carousel__nav--next {
        &.is-disabled {
            @apply cursor-not-allowed pointer-events-none opacity-20
        }
    }
