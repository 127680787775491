@tailwind utilities;

@layer utilities {
    /* ... */
}

/* AOS */
[data-aos="prlx-fade-up"] {
  opacity: 0;
  transform: translateY(5rem);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: none;
  }
}

[data-aos="prlx-fade-up--sm"] {
  opacity: 0;
  transform: translateY(3rem);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: none;
  }
}

[data-aos="prlx-zoom-out-left"] {
  opacity: 0;
  transform: translate3d(4rem, 0, 0) scale(1.075);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: none;
  }
}

[data-aos="prlx-zoom-out-right"] {
  opacity: 0;
  transform: translate3d(-4rem, 0, 0) scale(1.075);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: none;
  }
}

/* Lazysizes */
.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

.stop-scrolling {
    @apply absolute top-0 left-0 w-full h-full overflow-hidden;
}
