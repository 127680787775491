/*
 * Footer Accordion
 */

 .footer-accordion {
     /* ... */
 }

    .footer-accordion__item {
        .footer-accordion__btn {
            svg {
                transform-origin: center center;
                will-change: transform;

                .is-active & {
                    transform: rotate(180deg);
                }
            }
        }

        .footer-accordion__content {
            /* transition: all 0.3s ease-in-out; */
        }

        &.is-active {
            .footer-accordion__btn {
                svg {
                    transform: rotate(180deg);
                }
            }

            .footer-accordion__content {
                @apply mb-2.8;
            }
        }
    }
