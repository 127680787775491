/*
* Fonts
*/

@font-face {
    font-family: 'NeueHaasGroteskText Std Md';
    src: url('../../fonts/NHaasGroteskTXStd-65Md.woff2') format('woff2'),
        url('../../fonts/NHaasGroteskTXStd-65Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskDisp Pro';
    src: url('../../fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff2'),
        url('../../fonts/NHaasGroteskDSPro-55Rg.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskDisp Pro';
    src: url('../../fonts/NHaasGroteskDSPro-56It.woff2') format('woff2'),
        url('../../fonts/NHaasGroteskDSPro-56It.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
