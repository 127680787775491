/*
 * Pagination
 */

.pagination {
    /* ... */
}

    .pagination__page-no {
        &::after {
            content: '';
            @apply absolute -bottom-0.2 left-0 w-full h-0.2 bg-accent-light-blue scale-x-0 origin-left transition-all;
        }

        &.is-active {
            @apply text-accent-light-blue border-accent-light-blue;

            &::after {
                @apply scale-x-100;
            }
        }

        &:hover {
            @apply text-accent-light-blue;

            &::after {
                @apply scale-x-100;
            }
        }
    }

    .pagination__btn {
        @apply text-accent-light-blue;

        &.is-disabled {
            @apply opacity-0 pointer-events-none;
        }
    }

    .pagination__btn--prev {
    
    }

    .pagination__btn--next {
    
    }



