/*
 * Car park carousel
 */

.car-park-carousel {
    .flickity-page-dots {
        @apply absolute bottom-2 left-0 w-full;

        @screen md {
            display: none;
        }

        .dot {
            @apply bg-white w-0.8 h-0.8 opacity-80 scale-75 transition-all ml-0.5 mr-0.5;

            &.is-selected {
                @apply scale-105 opacity-100;
            }
        }
    }
}