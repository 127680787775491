/*
 * Typography
 */

 /*
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    /* ... */
}

.alpha,
.bravo,
.charlie,
.delta,
.echo,
.foxtrot {
    font-family: theme("fontFamily.headings");
    line-height: theme("lineHeight.normal");
    letter-spacing: theme("letterSpacing.wide");
}

.alpha {
    @apply text-alpha-fluid;
    margin-bottom: theme("spacing.[1.6]");
}

.bravo {
    @apply text-bravo-fluid;
    margin-bottom: theme("spacing.[0.8]");
}

.charlie {
    @apply text-charlie-fluid;
    margin-bottom: theme("spacing.[0.8]");
}

.delta {
    @apply text-delta-fluid;
    margin-bottom: theme("spacing.[0.4]");
}

.echo {
    @apply text-echo-fluid;
    margin-bottom: theme("spacing.[0.4]");
}

.foxtrot {
    @apply text-foxtrot-fluid;
    margin-bottom: theme("spacing.[0.4]");
}

/*
 * Body text
 */

.body-xl {
    @apply text-lg;
    line-height: theme("lineHeight.relaxed");
    letter-spacing: theme("letterSpacing.tightest");
    margin-bottom: theme("spacing[4.8]");
}

.body-lg {
    @apply text-md;
    line-height: theme("lineHeight.breezy");
    letter-spacing: theme("letterSpacing.tightest");
    margin-bottom: theme("spacing[3.2]");
}

.body-base {
    @apply text-base;
    line-height: theme("lineHeight.normal");
    letter-spacing: theme("letterSpacing.tightest");
    margin-bottom: theme("spacing[3.2]");
}

.body-sm {
    @apply text-sm;
    line-height: theme("lineHeight.loose");
    letter-spacing: theme("letterSpacing.tightest");
    margin-bottom: theme("spacing[2.4]");
}

.body-xs {
    @apply text-xs;
    line-height: theme("lineHeight.normal");
    letter-spacing: theme("letterSpacing.tightest");
}

p {
    /* @apply text-base; */
    line-height: theme("lineHeight.normal");
    letter-spacing: theme("letterSpacing.tightest");
    margin-bottom: theme("spacing[3.2]");

    /* &:last-of-type {
        @apply mb-0;
    } */
}

em {
    font-family: theme("fontFamily.base");
}

a {
    @apply text-accent-light-blue;
}

ul,
ol {
    @apply list-inside;
}

li {
    a:hover {
        @apply underline;
    }
}

ul {
    @apply list-disc;

    li {
        /* ... */
    }
}

ol {
    @apply list-decimal;

    li {
        /* ... */
    }
}

blockquote {
    margin-bottom: theme("spacing.3");

    a:hover {
        @apply underline;
    }
}

strong {
    @apply font-bold;
}

em {
    @apply italic;
}
