/*
 * Button link
 */

.btn-link {
    @apply relative inline-block w-fit-content pb-0.8 text-accent-light-blue overflow-hidden;

    &::before {
        content: "";
        @apply absolute bottom-0 left-0 w-full h-0.2 bg-accent-light-blue;
    }

    &::after {
        content: "";
        @apply absolute bottom-0 -left-1 w-1 h-0.2 bg-white;
        will-change: left;
        transition: all 0.5s ease-in-out;
    }

    &:hover {
        &::after {
            left: calc(100% + 1rem);
        }
    }

    &.disabled {
        @apply cursor-not-allowed text-pastel-light-blue;

        &::before {
            @apply bg-pastel-light-blue;
        }

        &:hover {
            &::after {
                @apply -left-1;
            }
        }
    }
}

.btn-link--light-blue {
    @apply text-accent-light-blue;

    &::before {
        @apply bg-accent-light-blue;
    }
}

.btn-link--dark-blue {
    @apply text-accent-dark-blue;

    &::before {
        @apply bg-accent-dark-blue;
    }
}
