/*
* Grid
*/

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 144rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    @screen sm {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    @screen md {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    @screen lg {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.4rem;
        margin-right: -0.4rem;

        @screen sm {
            margin-left: -0.8rem;
            margin-right: -0.8rem;
        }

        @screen lg {
            margin-left: -1.6rem;
            margin-right: -1.6rem;
        }
    }

        .col {
            padding-left: 0.4rem;
            padding-right: 0.4rem;

            @screen sm {
                padding-left: 0.8rem;
                padding-right: 0.8rem;
            }

            @screen lg {
                padding-left: 1.6rem;
                padding-right: 1.6rem;
            }
        }
