.desktop-nav {
    /* ... */
}

.desktop-nav__nav-item {
    @apply relative;

    &::after {
        content: '';
        @apply absolute bottom-0 left-0 w-full h-px bg-accent-dark-blue scale-x-0 transition-all origin-left;
    }

    &:hover {
        @apply text-accent-dark-blue no-underline;

        &::after {
            @apply scale-x-100;
        }

        svg {
            @apply text-accent-dark-blue;
        }
    }

    &.is-active {
        @apply text-accent-dark-blue;

        &::after {
            @apply scale-x-100;
        }

        svg {
            @apply rotate-180;
        }
    }
}



.desktop-nav__dropdown {

    &--car-parks {
        @apply left-6/12 -translate-x-6/12;

        &::before {
            left: calc(16.667% - 1.75rem)
        }
    }

    &--products-and-services {
        @apply left-6/12 -translate-x-6/12;

        &::before {
            left: calc(45% - 1.75rem);
        }
    }

    &--partnerships {
        @apply left-6/12 -translate-x-6/12;

        &::before {
            left: calc(45% - 1.75rem);
        }
    }

    &::before {
        content: '';
        @apply fixed bottom-full w-0 h-0;
        border-width: 0 2.6rem 3.5rem 2.6rem;
        border-color: transparent transparent #ffffff transparent;
    }

    &.is-active {
        @apply pointer-events-auto opacity-100;
    }
}

.car-park-dropdown {

}
