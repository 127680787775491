/*
 * Parallax title text
 */

.parallax-title-text {

}

    .parallax-title-text__image-container {
        width: calc( ( 50% + (100vw - 100%) / 2) ); /* 100vw - 100% (the width of the container) */
    }

        .parallax-title-text__image {


        }
