/*
 * Tariff component on carpark page
 */

.tariff {
    .tarrif__list {
        @screen md {
            max-height: 22rem;
        }
    }

    .tariff__list-item {
        padding-left: 5.4rem;
    }

    .tariff__list-item:nth-child(-n+6) {
        @screen md {
            padding-left: 7.4rem;
        }
        @screen lg {
            padding-left: 8.4rem;
        }
    }

    .tariff__list-item:not(:nth-child(-n+6)) {
        @screen md {
            padding-left: 0.8rem;
        }
        @screen lg {
            padding-left: 1.6rem;
        }
    }
}
